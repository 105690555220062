import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { Inventory } from 'src/app/shared/models/inventory.model';
import { ModalService } from './modal.service';
import { Observable, of, tap } from 'rxjs';
import { InventoryTicket, TicketActions } from 'src/app/shared/models/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private http: HttpClient,
    private modal: ModalService,
  ){}

  private endpoint: string  = '/customer/inventory/';

  /**
   * Instanciamos la WriteableSignal inventory, que almacenará el inventario del usuario.
   * Para prevenir modificaciones directas, exponemos un getter getInventory que devuelve el valor de inventory más actualizado. 
   * El setter es la función fetchInventory, que recibe un tipo de usuario y hace una petición al endpoint. 
   * @type {WritableSignal<Inventory[] | undefined>} inventory
   * @type {Signal<Inventory[] | undefined>} getInventory
   */
  private inventory:              WritableSignal<Inventory[] | undefined>   = signal(undefined);
  public readonly getInventory:   Signal<Inventory[] | undefined>           = computed(() => this.inventory());

  /**
   * TICKET INVENTORY
   */
  private ticketInventory:         WritableSignal<Map<string, InventoryTicket[]>> = signal(new Map());
  public get getTicketInventory(): Map<string, InventoryTicket[]>{
    return this.ticketInventory()
  };

  /**
   * TICKET DETAIL
   */
  private currentTicket:    WritableSignal<InventoryTicket[] | undefined> = signal(undefined);
  public readonly tickets:  Signal<InventoryTicket[] | undefined>         = computed(() => this.currentTicket());


  /**
   * Método para obtener el inventario del usuario.
   * Este método necesita el parámetro type para saber que parámetros crear en la petición.
   * Posteriormente, si es exitosa, seteamos el valor de inventory con el inventario obtenido y si no 
   * mostramos un error en consola. // To Do Throw Error
   * @param {string} type 
   * @returns void
   */
  public fetchInventory(type: 'Men'|'Women'|'All'): void {
    
    // Si es women creamos un objeto HttpParams con el parámetro, si no creamos un objeto vacio.
    const params: HttpParams = type === 'Women' ?
       new HttpParams({fromObject: {flow: 'women_exchange'}}) : 
       new HttpParams({});
    
    // Si la petición viene de Exchange, cambiamos el endpoint, si no mantenemos el mismo.
    const endpoint: string = type === 'Men' || type === 'Women' ? 
      '/ticket_exchange' + this.endpoint :
       this.endpoint;

    // Hacemos la petición al endpoint con los parámetros creados y subscribimos a la respuesta.
    this.http.get<Inventory[]>(endpoint, { params: params }).subscribe({
      next:  inventory => this.inventory.set(inventory),
      error: error => {

        // Mostramos error en consola
        console.error(error);

        // Seteamos el inventario vacio para evitar errores en la vista
        this.inventory.set([]);

        // Mostramos un modal de error
        this.modal.createErrorModal({
          content: error.error.message,
        })

      }
    });

  }

  public setCurrentTicket(ticket: InventoryTicket[]): void {
    this.currentTicket.set(ticket);
  }

  public getInventoryById(id: string, force: boolean = false): Observable<InventoryTicket[]> {

    const ticket: InventoryTicket[] | undefined = this.ticketInventory().get(id);
    return ticket && !force ? of(ticket) : this.getInventoryTicket(id);

  }

  public transferTicket(mode: TicketActions, ticket_id: number, associate_id: string): Observable<any> {
    
    const endpoint: string = `/customer/tickets/${ticket_id}/${mode.toLowerCase()}/`,
          body = mode === 'Forward' ? { associate_id } : {};

    return this.http.post(endpoint, body)
  }

  public updateTicketInventory(id: string, value: InventoryTicket[]): void {

    if(this.ticketInventory().has(id)) {
      this.ticketInventory.update(inventory => inventory.set(id, value));
    }
    
    return;

  }

  private getInventoryTicket(id: string): Observable<InventoryTicket[]> { 
    
    const endpoint: string =  `/customer/event/${id}/`;

    return this.http.get<InventoryTicket[]>(endpoint).pipe(tap(
      ticket => {        
        this.ticketInventory.update(inventory => inventory.set(id, ticket))
      }
    ));

  }

  
}
